<script lang="ts">
    import { Icon, MagnifyingGlass } from 'svelte-hero-icons';
    export let searchTerm;
    export let activeFilter;
    export let searchFilters;
</script>

<form class="search-box-wrapper w-100 relative">
    <div class="input-group input-group-seamless-append focus-within:!border-bmn-500 focus:!border-bmn-500">
        <input
            type="text"
            class="form-control homeSearch shadow-none"
            required
            aria-label="Text input with dropdown button"
            placeholder="Search"
            name="query"
            autocomplete="off"
            value="{searchTerm ? searchTerm : ''}"
        />

        <div class="input-group-append">
            <span class="input-group-text">
                <button
                    class="h-pill file-upload-button rounded border-0 bg-bmn-50 !text-bmn-500 transition-all duration-300 hover:bg-bmn-100 dark:bg-bmn-400 dark:bg-opacity-10 dark:text-bmn-500 dark:hover:bg-opacity-20"
                    type="submit"
                >
                    <Icon src="{MagnifyingGlass}" size="24px" />
                </button>
            </span>
        </div>
    </div>
    <div class="searchSuggestionContainer w-100 absolute hidden shadow-lg shadow-neutral-600">
        <ul></ul>
    </div>
    <input type="hidden" name="filter" value="{activeFilter !== false ? activeFilter : 'top'}" />

    {#if searchFilters?.gender}
        <input type="hidden" name="gender" value="{searchFilters.gender}" />
    {/if}

    {#if searchFilters?.min_age}
        <input type="hidden" name="min_age" value="{searchFilters.min_age}" />
    {/if}

    {#if searchFilters?.max_age}
        <input type="hidden" name="max_age" value="{searchFilters.max_age}" />
    {/if}

    {#if searchFilters?.location}
        <input type="hidden" name="location" value="{searchFilters.location}" />
    {/if}
</form>
